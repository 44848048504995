<script>
import LearnMore from "@/views/components/LearnMore.vue";
import { getNewsList } from "@/api/newsCenter";
import { NEWS_CENTER_TYPE } from "@/utils/enum";
import moment from "moment/moment";

export default {
  name: "NewsCenter",
  computed: {
    moment() {
      return moment;
    }
  },
  components: {
    LearnMore
  },
  data() {
    return {
      itemList: []
    };
  },
  mounted() {
    this.getNewsList();
  },
  methods: {
    /**
     * 查询新闻列表
     */
    getNewsList() {
      this.loading = true;
      const params = {
        type: NEWS_CENTER_TYPE.news,
        page: 1,
        limit: 3
      };
      getNewsList(params)
        .then(resp => {
          const data = resp.data;
          this.itemList = data.data || [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * 查看更多
     */
    seeMoreFun(id) {
      this.$store.commit("SET_SELECTEDKEYS", "newsCenter");
      let path = "/newsCenter?type=news";
      if (id && id !== 0) {
        path += "&id=" + id;
      }
      this.$router.push({ path });
    }
  }
};
</script>

<template>
  <div class="news_center">
    <div class="title_warp">
      <div class="title_div_warp">
        <div class="content_title_warp">
          {{ this.$t("home.newsCenter.title") }}
        </div>
        <div class="content_but_warp" />
      </div>
    </div>

    <div class="news_center_des">
      <div class="news_center_des_left">
        <div
          class="content_item"
          v-for="item in itemList"
          :key="item.key"
          @click="seeMoreFun(item.id)"
        >
          <el-image :src="item.default_img" fit="scale-down" lazy />
          <div class="des">
            <div class="title">{{ item.title }}</div>
            <div class="info" v-html="item.sub_title || item.content"></div>
            <div class="time">
              {{ moment(item.created_at).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </div>
        </div>
      </div>
      <div class="news_center_des_right">
        <div class="right_item">
          <div class="item1">
            <el-image
              :src="require('@/assets/home/news_right_01.png')"
              fit="scale-down"
              lazy
            />
            <!--            专家工作-->
            <div class="item_des">
              {{ this.$t("home.newsCenter.expertWork") }}
            </div>
          </div>
          <div class="item2">
            <el-image
              :src="require('@/assets/home/news_right_02.png')"
              fit="scale-down"
              lazy
            />
            <!--            项目申报-->
            <div class="item_des">
              {{ this.$t("home.newsCenter.projectApplication") }}
            </div>
          </div>
        </div>
        <div class="right_item">
          <div class="item2">
            <el-image
              :src="require('@/assets/home/news_right_03.png')"
              fit="scale-down"
              lazy
            />
            <!--            项目监管-->
            <div class="item_des">
              {{ this.$t("home.newsCenter.projectSupervision") }}
            </div>
            <!--            艺术部分-->
            <div class="item_des_info">
              ({{ this.$t("home.newsCenter.projectSupervisionArt") }})
            </div>
          </div>
          <div class="item1">
            <el-image
              :src="require('@/assets/home/news_right_04.png')"
              fit="scale-down"
              lazy
            />
            <!--            项目监管-->
            <div class="item_des">
              {{ this.$t("home.newsCenter.projectSupervision") }}
            </div>
            <!--            经费部分-->
            <div class="item_des_info">
              ({{ this.$t("home.newsCenter.projectSupervisionExpenditure") }})
            </div>
          </div>
        </div>
      </div>
    </div>
    <LearnMore />
  </div>
</template>

<style scoped lang="scss">
@import "../../styles/variables.scss";
@import "./index.scss";

.news_center {
  width: calc(100% - 20%);
  //height: 790px;
  background: url("../../assets/home/bg/news_center.png") no-repeat;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  padding-top: 40px;
  padding: 40px 10%;
  .title_warp {
    display: flex;
    .title_div_warp {
      text-align: center;
    }
    .content_title_warp {
      //width: 148px;
      height: 60px;
      font-weight: 400;
      font-size: 36px;
      color: #000000;
      line-height: 54px;
      font-style: normal;
    }
    .content_but_warp {
      background: url("../../assets/home/business_bg.png") no-repeat;
      background-size: cover; /* 背景图片覆盖整个容器 */
      background-position: center; /* 背景图片居中 */
      height: 12px;
      //width: 150px;
    }
  }

  .news_center_des {
    display: flex;
    margin-top: 20px;
    .news_center_des_left {
      width: calc(100% - 364px);
      .content_item {
        display: flex;
        margin-bottom: 26px;
        cursor: pointer;
        .des {
          margin: 0 20px;
          width: calc(100% - 150px);
          .title {
            font-weight: bold;
          }
          .title:hover {
            transform: scale(1.02);
            transition: all 1s;
          }
          .info {
            font-size: 0.8rem;
            margin-top: 8px;
            color: rgba(0, 0, 0, 0.5);
            line-height: 24px;
            display: -webkit-box;
            word-break: break-all;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .time {
            margin-top: 8px;
            color: $theme-red;
          }
        }
        /deep/ {
          .el-image {
            width: 150px;
          }
          .el-image:hover {
            transform: scale(1.06);
            transition: all 1s;
          }
        }
      }
    }
    .news_center_des_right {
      width: 364px;
      color: #ffffff;
      text-align: center;
      margin-left: 10px;
      .right_item {
        display: flex;
        .item1,
        .item2 {
          width: 50%;
          //height: 222px;
          padding: 20% 0;
          cursor: pointer;
          background-size: cover; /* 背景图片覆盖整个容器 */
          background-position: center; /* 背景图片居中 */
          /deep/ .el-image__inner {
            width: 44px;
            height: 44px;
          }
        }
        .item1 {
          background: url("../../assets/home/news_item1.png") no-repeat;
        }
        .item2 {
          background: url("../../assets/home/news_item2.png") no-repeat;
        }
        .item1:hover,
        .item2:hover {
          transform: scale(1.06);
          transition: all 1s;
        }
        .item_des {
          margin-top: 14px;
          font-size: 1.2rem;
          padding: 0px 10px;
        }
        .item_des_info {
          margin-top: 6px;
          font-size: 0.8rem;
        }
      }
    }
  }
}
</style>
