<script>
export default {
  name: "ArtActivity",
  components: {},
  data() {
    return {
      isCheck: 1 // 选中的标签
    };
  },
  mounted() {},
  methods: {
    /**
     * 切换标签
     */
    checkTag(val) {
      this.isCheck = val;
    }
  },
  beforeDestroy() {}
};
</script>

<template>
  <div class="art_activity">
    <div class="title_comm_warp">
      <div class="title_comm_div_warp">
        <div class="content_comm_warp">
          {{ this.$t("home.artActivity.title") }}
        </div>
        <div class="content_but_comm_warp" />
      </div>
    </div>
    <div class="art_activity_content_warp">
      <div class="content_item_warp">
        <div
          :class="isCheck === 1 ? 'select left_warp' : 'no_select left_warp'"
          v-on:mouseover="checkTag(1)"
        >
          <!--          复兴之路-->
          <div class="ch">
            {{ this.$t("home.artActivity.roadRejuvenation") }}
          </div>
          <div class="en">The Road to Revival</div>
        </div>
        <div
          :class="
            isCheck === 1 ? 'is_show right_warp' : 'is_no_show right_warp'
          "
        >
          <!--          复兴之路-->
          <div class="title_zh">
            {{ this.$t("home.artActivity.roadRejuvenation") }}
          </div>
          <div class="title_en">The Road to Revival</div>
          <div class="desc">
            在全党全国上下认真学习贯彻党的十八大精神的热潮中，中共中央总书记、中央军委主席习沂平和中史政治局常委李克强、张德江、俞正声、刘云山、王岐山。张高丽等于2012年11月29日去到国家博物馆，参观了《兴之路》的基本陈列。参观过程中，习主席发表了重要讲话，他表示，《复兴之路》这个展览，回顾了中华民族的...
          </div>
          <el-image
            :src="require('@/assets/home/artActivity_fx.png')"
            fit="scale-down"
            lazy
          />
        </div>
      </div>

      <div class="content_item_warp">
        <div
          :class="isCheck === 2 ? 'select left_warp' : 'no_select left_warp'"
          v-on:mouseover="checkTag(2)"
        >
          <!--          社会活动-->
          <div class="ch">{{ this.$t("home.artActivity.socialActivity") }}</div>
          <div class="en">social activities</div>
        </div>
        <div
          :class="
            isCheck === 2 ? 'is_show right_warp' : 'is_no_show right_warp'
          "
        >
          <!--          社会活动-->
          <div class="title_zh">
            {{ this.$t("home.artActivity.socialActivity") }}
          </div>
          <div class="title_en">social activities</div>
          <div class="desc">
            在全党全国上下认真学习贯彻党的十八大精神的热潮中，中共中央总书记、中央军委主席习沂平和中史政治局常委李克强、张德江、俞正声、刘云山、王岐山。张高丽等于2012年11月29日去到国家博物馆，参观了《兴之路》的基本陈列。参观过程中，习主席发表了重要讲话，他表示，《复兴之路》这个展览，回顾了中华民族的...
          </div>
          <el-image
            :src="require('@/assets/home/artActivity_fx.png')"
            fit="scale-down"
            lazy
          />
        </div>
      </div>

      <div class="content_item_warp">
        <div
          :class="isCheck === 3 ? 'select left_warp' : 'no_select left_warp'"
          v-on:mouseover="checkTag(3)"
        >
          <!--          自主活动-->
          <div class="ch">
            {{ this.$t("home.artActivity.autonomousActivity") }}
          </div>
          <div class="en">Locomotor activity</div>
        </div>
        <div
          :class="
            isCheck === 3 ? 'is_show right_warp' : 'is_no_show right_warp'
          "
        >
          <!--          自主活动-->
          <div class="title_zh">
            {{ this.$t("home.artActivity.autonomousActivity") }}
          </div>
          <div class="title_en">Locomotor activity</div>
          <div class="desc">
            在全党全国上下认真学习贯彻党的十八大精神的热潮中，中共中央总书记、中央军委主席习沂平和中史政治局常委李克强、张德江、俞正声、刘云山、王岐山。张高丽等于2012年11月29日去到国家博物馆，参观了《兴之路》的基本陈列。参观过程中，习主席发表了重要讲话，他表示，《复兴之路》这个展览，回顾了中华民族的...
          </div>
          <el-image
            :src="require('@/assets/home/artActivity_fx.png')"
            fit="scale-down"
            lazy
          />
        </div>
      </div>

      <div class="content_item_warp">
        <div
          :class="isCheck === 4 ? 'select left_warp' : 'no_select left_warp'"
          v-on:mouseover="checkTag(4)"
        >
          <!--          国际交流-->
          <div class="ch">
            {{ this.$t("home.artActivity.internationalExchange") }}
          </div>
          <div class="en">International Exchange</div>
        </div>
        <div
          :class="
            isCheck === 4 ? 'is_show right_warp' : 'is_no_show right_warp'
          "
        >
          <!--          国际交流-->
          <div class="title_zh">
            {{ this.$t("home.artActivity.internationalExchange") }}
          </div>
          <div class="title_en">International Exchange</div>
          <div class="desc">
            在全党全国上下认真学习贯彻党的十八大精神的热潮中，中共中央总书记、中央军委主席习沂平和中史政治局常委李克强、张德江、俞正声、刘云山、王岐山。张高丽等于2012年11月29日去到国家博物馆，参观了《兴之路》的基本陈列。参观过程中，习主席发表了重要讲话，他表示，《复兴之路》这个展览，回顾了中华民族的...
          </div>
          <el-image
            :src="require('@/assets/home/artActivity_fx.png')"
            fit="scale-down"
            lazy
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "index";

.art_activity {
  width: calc(100% - 20%);
  height: 760px;
  background: url("../../assets/home/bg/artActivity.png") no-repeat;
  background-size: cover; /* 背景图片覆盖整个容器 */
  background-position: center; /* 背景图片居中 */
  padding: 0 10%;
  .art_activity_content_warp {
    margin-top: 38px;
    display: flex;
    justify-content: space-between;
    .content_item_warp {
      display: flex;
      transition: all 2s; /* 过渡效果持续2秒 */
      .select {
        background: url("../../assets/home/artActivity_select.png") no-repeat;
        font-weight: 500;
      }
      .select:hover {
        transform: scale(1.06);
        transition: all 1s;
      }
      .no_select {
        background: url("../../assets/home/artActivity_no_select.png") no-repeat;
      }
      .is_show {
        //transition: opacity 0.5s ease-in-out;
        //opacity: 1; /* 初始可见 */
        //animation-delay: 0.5s;
        display: block;
      }
      .is_no_show {
        //animation-delay: 0.5s;
        //opacity: 0; /* 设置为透明，实现隐藏 */
        display: none;
      }
      .left_warp {
        display: flex;
        //width: 178px;
        height: 506px;
        line-height: 40px;
        background-size: cover; /* 背景图片覆盖整个容器 */
        background-position: center; /* 背景图片居中 */
        padding: 38px 48px;
        font-size: 1.4rem;
        cursor: pointer;
        .ch {
          writing-mode: vertical-rl;
          letter-spacing: 20px;
        }
        .en {
          writing-mode: vertical-rl;
          text-orientation: sideways;
          text-transform: uppercase;
        }
      }
      .right_warp {
        padding: 18px 10px 0 16px;
        color: #000000;
        .title_zh {
          font-size: 1.6rem;
        }
        .title_en {
          margin-top: 20px;
          font-size: 1.3rem;
          text-transform: uppercase;
        }
        .desc {
          margin: 10px 0 28px;
          font-size: 0.7rem;
          line-height: 24px;
        }
      }
    }
  }

  /*最大屏幕分辨率为1280px*/
  @media screen and (max-width: 1280px) {
    .is_show {
      width: 300px;
    }
  }
  /*最小屏幕分辨率为1280px且最大为1440px*/
  @media (min-width: 1280px) and (max-width: 1440px) {
    .is_show {
      width: 300px;
    }
  }
  /*最小屏幕分辨率为1440px且最大为1680px*/
  @media (min-width: 1441px) and (max-width: 1680px) {
    .is_show {
      width: 334px;
    }
  }
  /*最小屏幕分辨率为1920px*/
  @media (min-width: 1681px) and (max-width: 1920px) {
    .is_show {
      width: 434px;
    }
    .right_warp {
      /deep/ {
        .el-image {
          height: 224px;
        }
      }
    }
  }
  /*最小屏幕分辨率为2560px*/
  @media (min-width: 1921px) and (max-width: 2560px) {
    .is_show {
      width: 740px;
    }
    .right_warp {
      /deep/ {
        .el-image {
          height: 300px;
        }
      }
    }
  }
  /*最小屏幕分辨率为2560px*/
  @media (min-width: 2561px) {
    .is_show {
      width: 740px;
    }
    .right_warp {
      /deep/ {
        .el-image {
          height: 300px;
        }
      }
    }
  }

  /deep/ .el-image:hover {
    //width: 50px;
    //height: 50px;
    transform: scale(1.06);
    transition: all 1s;
  }
}
</style>
